<template>
  <div>
    <form>
      <v-row>
        <v-col
          sm="12"
          md="12"
        >
          <v-row>
            <v-col
              cols="6"
              md="6"
            >
              <v-card
                tile
              >
                <v-img
                  :src="uploadImage.demoImageUrl"
                  :lazy-src="'/static/placeholder.jpg'"
                  aspect-ratio="1"
                />
              </v-card>
            </v-col>

            <v-col
              cols="6"
              md="6"
            >
              <v-card
                height="100%"
                width="100%"
                aspect-ratio="1"
                tile
                style="z-index: 2;"
                :color="isImageLoadedToMemory() ? '' : `${this.$vuetify.theme.themes.light.cta}`"
                @click="$refs.fileField.click()"
              >
                <template>
                  <input
                    ref="fileField"
                    type="file"
                    class="d-none"
                    accept="image/*"
                    capture="camera"
                    @change="onImageInputChange"
                  >
                  <div>
                    <v-img
                      v-if="isImageLoadedToMemory()"
                      :src="uploadImage.displayableImageUrl"
                      height="100%"
                      width="100%"
                      aspect-ratio="1"
                    />
                    <div
                      v-else
                      tile
                      height="100%"
                      width="100%"
                    >
                      <v-card-text>
                        <div>
                          {{ $t('IMAGE_CAPTURE_TAKE_IMAGE_CTA_TEXT') }}
                        </div>
                        <div class="text-center mt-1">
                          <v-icon x-large>
                            mdi-gesture-tap
                          </v-icon>
                        </div>
                      </v-card-text>
                      <v-card-text />
                    </div>
                  </div>
                </template>
              </v-card>
            </v-col>
          </v-row>
          <v-row
            class="pt-0 pb-0 mt-0 mb-0"
          >
            <v-col
              class="pt-0 pb-0 mt-0 mb-0"
              cols="6"
              md="6"
            >
              <v-card
                class="rounded-md"
                min-height="25"
                flat
              >
                <div class="text-center font-weight-light">
                  {{ $t('IMAGE_CAPTURE_DEMO_IMAGE_TEXT') }}
                </div>
              </v-card>
            </v-col>
            <v-col
              class="pt-0 pb-0 mt-0 mb-0"
              cols="6"
              md="6"
            >
              <v-card
                class="rounded-md"
                min-height="25"
                flat
              >
                <div class="text-center font-weight-light">
                  {{ $t('IMAGE_CAPTURE_TAKE_IMAGE_TEXT') }}
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </form>

    <v-row>
      <v-col>
        <v-slide-x-transition>
          <v-card
            v-show="uploaded"
            flat
          >
            <v-row>
              <v-col cols="12 mt-1">
                <div class="text-center success--text">
                  {{ showOptionToRetakeImageAgainText }} &nbsp;&nbsp; <v-icon
                    small
                    color="success"
                  >
                    mdi-thumb-up
                  </v-icon>
                </div>
              </v-col>
              <v-col cols="6">
                <template>
                  <input
                    ref="fileField"
                    type="file"
                    class="d-none"
                    accept="image/*"
                    capture="camera"
                    @change="onImageInputChange"
                  >
                  <div
                    @click="$refs.fileField.click()"
                  >
                    <v-btn
                      block
                      min-width="100"
                    >
                      {{ $t('IMAGE_TRY_AGAIN_BUTTON_TEXT') }}
                    </v-btn>
                  </div>
                </template>
              </v-col>
              <v-col cols="6">
                <v-btn
                  block
                  color="primary"
                  min-width="100"
                  @click="next()"
                >
                  {{ $t('WIDGET_NEXT_BUTTON_TEXT') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-slide-x-transition>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card>
          <v-card-text>
            <v-row>
              <v-col
                cols="4"
                class="text-center"
              >
                <v-icon class="mb-2">
                  mdi-mirror
                </v-icon>
                <div class="caption">
                  {{ $t('INSTRUCTION_HOW_TO_TAKE_AN_IMAGE_TIP_1') }}
                </div>
              </v-col>
              <v-col
                cols="4"
                class="text-center"
              >
                <v-icon class="mb-2">
                  mdi-flash-circle
                </v-icon>
                <div class="caption">
                  {{ $t('INSTRUCTION_HOW_TO_TAKE_AN_IMAGE_TIP_2') }}
                </div>
              </v-col>
              <v-col
                cols="4"
                class="text-center"
              >
                <v-icon class="mb-2">
                  mdi-cellphone
                </v-icon>
                <div class="caption">
                  {{ $t('INSTRUCTION_HOW_TO_TAKE_AN_IMAGE_TIP_3') }}
                </div>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider />

          <v-card-title>
            {{ $t(uploadImage.title) }}
          </v-card-title>

          <v-card-subtitle>
            {{ $t(uploadImage.subheading) }} <strong>{{ $t(uploadImage.subheadingStrong) }}</strong>
          </v-card-subtitle>

          <v-card-actions>
            <v-btn
              text
              @click="videoOverlay = true"
            >
              {{ $t('HOW_TO_TAKE_IMAGES_WATCH_VIDEO_BUTTON_TEXT') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-overlay
      :absolute="absolute"
      :opacity="opacity"
      :value="videoOverlay"
    >
      <v-card
        transparent
        :width="minVideoCardDimensions.w"
        :height="minVideoCardDimensions.h"
      >
        <v-card-title>
          {{ $t(uploadImage.title) }}
        </v-card-title>
        <v-card-subtitle>
          {{ uploadImage.howToVideoDuration }}
        </v-card-subtitle>
        <v-responsive
          class="d-flex align-center"
          height="100%"
          width="100%"
        >
          <v-card
            ref="embeddedVideo"
            cols="12"
            flat
          >
            <v-card-text>
              <div>
                <youtube
                  :video-id="demoVideoYoutubeId"
                  player-width="100%"
                  :player-height="videoDimensions.height"
                />
              </div>
            </v-card-text>
          </v-card>
        </v-responsive>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            @click="videoOverlay = false"
          >
            Luk
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-overlay>

    <v-snackbar
      v-model="demoSnackbar"
      color="primary"
      top
      center
    >
      {{ activeDemoSnackbarText }}
    </v-snackbar>
  </div>
</template>

<script>
  export default {
    name: 'ImageCaptureTab',
    props: {
      uploadImage: {
        type: Object,
        required: true,
      },
      skippable: {
        type: Boolean,
        default: true,
      },
    },

    data: () => ({
      demoSnackbar: false,
      activeDemoSnackbarText: '',
      show: false,
      opacity: 1.0,
      donateOverlay: false,
      videoOverlay: false,
      absolute: false,
      successfulDonate: false,
      showOptionToRetakeImageAgain: false,
      showOptionToRetakeImageAgainText: '',

      videoWidthToHeightRatio: 640 / 360, // TBD whether this is 'correct'.
      videoToScreenRatioOnSmallerScreens: 1.0,
      videoToScreenRatioOnLargerScreens: 0.7,
    }),

    computed: {
      demoVideoYoutubeId () {
        return this.$t(this.uploadImage.howToVideoId)
      },
      videoDimensions () {
        const mdAndUp = this.$vuetify.breakpoint.mdAndUp
        let videoToScreenRatio = this.videoToScreenRatioOnSmallerScreens
        if (mdAndUp) {
          videoToScreenRatio = this.videoToScreenRatioOnLargerScreens
        }
        const w = window.innerWidth * videoToScreenRatio
        const ratio = this.videoWidthToHeightRatio
        const h = w / ratio
        return { width: w, height: h }
      },
      minVideoCardDimensions () {
        const _dim = this.videoDimensions
        const h = _dim.h + 5
        const w = _dim.w + 5
        return { width: w, height: h }
      },
      uploaded () {
        return this.uploadImage.uploaded
      },
    },

    methods: {
      onImageInputChange (event) {
        this.$emit('onImageInputChange', event)
        this._showOptionToRetakeImageAgain()
      },
      isImageLoadedToMemory () {
        return (this.uploadImage && this.uploadImage.displayableImageUrl)
      },
      skipImage () {
        this.$emit('skipImage', this.uploadImage)
      },
      _showOptionToRetakeImageAgain () {
        /** Pure UX utility. */
        this.showOptionToRetakeImageAgainText = this.$t(this.uploadImage.successText)
      },
      async next () {
        /** Convenience button */
        await this.$vuetify.goTo(0, 0)
        this.$emit('click:next')
      },
    },
  }
</script>

<style lang="sass">
</style>
